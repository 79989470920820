html,
body {
  /* width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px;
    overflow: auto; */
    background: #1B1B1B !important;
}

.sidebar-shadow {
  box-shadow: rgb(0 0 0 / 20%) 0px 7px 12px 0px !important;
}